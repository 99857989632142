<template>
  <div class="exam-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb ref="breadcrumb" class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>考试列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="loading__div" style="padding: 10px" v-loading="loading" element-loading-text="加载中">
      <div class="exam-content yt-flex-layout yt-flexColumns">
        <div class="search-btn__style yt-flex-layout">
          <div>
            <el-button @click="createNewPaper" type="primary" size="small">
              <div>
                <YTIcon @click="createNewPaper" href="#icon-xinzeng" />
                <p>新建考试</p>
              </div>
            </el-button>
            <el-tooltip content="会自动过滤不可删除的考试" effect="light">
              <el-button @click="checkLength" size="small">
                <div>
                  <YTIcon @click="checkLength" href="#icon-shanchu" />
                  <p>删除考试</p>
                </div>
              </el-button>
            </el-tooltip>
          </div>
          <div>
            <el-input
              v-model="searchObj.examName"
              @keyup.enter.native="enterSearch()"
              class="set_suffix"
              size="small"
              placeholder="通过考试名称模糊搜索"
              style="width: 200px"
            >
              <template slot="suffix">
                <YTIcon href="#icon-sousuo" style="fill: #A3A5B0" />
              </template>
            </el-input>
            <el-select clearable size="small" style="width: 200px;margin: 0 12px" v-model="searchObj.status" placeholder="请选择状态">
              <el-option :value="1" label="未开始" />
              <el-option :value="2" label="进行中" />
              <el-option :value="3" label="已结束" />
              <el-option :value="4" label="未发布" />
            </el-select>
            <el-select clearable size="small" style="width: 200px;margin-right: 12px" v-model="searchObj.external" placeholder="请选择考试用途">
              <el-option :value="0" label="内部评测" />
              <el-option :value="1" label="外部招聘" />
            </el-select>
            <el-button @click="enterSearch()" size="small" type="primary" v-text="'查询'" />
            <el-button @click="reset()" size="small" v-text="'重置'" />
          </div>
        </div>
        <template v-if="list.length">
          <div class="exam-data card yt-flex-layout" v-for="(l, lIndex) in list" :key="lIndex">
            <div>
              <el-checkbox :disabled="validDel(l)" v-model="l.selected" @change="handleChange" />
            </div>
            <div class="info__style yt-flex-layout yt-flexColumns">
              <div class="div__s yt-flex-layout">
                <span class="info-name">{{ l.name }}</span>
                <span
                  class="color__style"
                  :style="{
                    color: colorList[l.status],
                    background: colorBGList[l.status]
                  }"
                  >{{ l.status | getStatus }}</span
                >
                <span class="type-span__style" :style="{ backgroundColor: l.external ? '#FFCC3E' : '' }">{{
                  !l.external ? '内部评测' : '外部招聘'
                }}</span>
              </div>
              <div class="yt-flex-layout" style="justify-content: space-between">
                <div class="div_s_1 div_tip yt-flex-layout yt-flexColumns">
                  <span>总分：{{ l.total ? l.total + '分' : '—' }}</span>
                  <span>题量：{{ l.questionAmount || '0' }}题</span>
                </div>
                <div class="div_s_1 div_tip yt-flex-layout yt-flexColumns">
                  <span>考试人数：{{ l.examineeNum || '0' }}人</span>
                  <span>提交人次：{{ l.submitCount || '0' }}人</span>
                </div>
                <div class="div_s_1 div_tip yt-flex-layout yt-flexColumns">
                  <span>开始时间：{{ $formatTime(l.startTime, 'yyyy-MM-dd') }}</span>
                  <span>结束时间：{{ $formatTime(l.endTime, 'yyyy-MM-dd') }}</span>
                </div>
                <div class="div_s_1 div_tip yt-flex-layout yt-flexColumns">
                  <span>创建人：{{ l.creatorName || '—' }}</span>
                  <span>创建时间：{{ $formatTime(l.createdTime, 'yyyy-MM-dd') }}</span>
                </div>
              </div>
            </div>
            <div class="btn__style yt-flex-layout">
              <div v-for="(s, sIndex) in getBtn(l.status)" :key="sIndex">
                <el-button @click="examSet(l, s)" type="text" v-text="s" />
                <el-divider v-if="sIndex !== getBtn(l.status).length - 1" direction="vertical" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-hint">
            <div>
              <YTIcon :href="'#icon-zanwushuju'" slot="reference" style="width:120px;height:124px;"></YTIcon>
              <p>暂时没有内容</p>
            </div>
          </div>
        </template>
        <div class="page__style">
          <Page
            class="yt-page"
            :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
            :total="total"
            :current="page + 1"
            :page-size-opts="[10, 20, 40, 100]"
            :page-size="size"
            show-elevator
            show-sizer
            show-total
            @on-change="changePage"
            @on-page-size-change="changeSize"
          />
        </div>
      </div>
    </div>

    <el-dialog title="发布失败" width="400px" :visible.sync="setDialog" center>
      <div style="text-align: center;color: #333;font-size: 14px">请{{ postTitle }}后再重新发布考试</div>
      <div slot="footer">
        <el-button size="small" type="primary" @click="toSetExam(postTitle)">{{ postTitle }}</el-button>
        <el-button size="small" class="el-button--default" @click="setDialog = false">取消</el-button>
      </div>
    </el-dialog>

    <DeleteModal
      class="delModal"
      v-model="deleteVisible"
      title="确认删除考试吗？"
      :loading="deleteLoading"
      @on-ok="deleteExam()"
      @on-cancel="deleteVisible = false"
    >
      <p>删除考试后不可恢复，你还要继续吗？</p>
    </DeleteModal>

    <DeleteModal
      class="delModal"
      v-model="closeVisible"
      title="确认关闭本场考试吗？"
      :loading="deleteLoading"
      @on-ok="submitCloseExam(clickRow)"
      @on-cancel="closeVisible = false"
    >
      <p>关闭后不可恢复，你还要继续吗？</p>
    </DeleteModal>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import newExamManageApi from '@api/newExamManage'
import DeleteModal from '@components/common/DeleteModal'
import { handleParams } from '@util/util'
export default {
  name: 'NewExamList',
  components: { YTIcon, DeleteModal },
  data() {
    return {
      colorList: ['', '#FF5050', '#4CE47A', '#666666', '#448bff'],
      colorBGList: ['', '#FFEBEB', '#EFFFF4', '#F0F0F0', '#DAE9FC'],
      list: [],
      selectedExamId: [],
      loading: false,
      waitLoading: null,
      deleteLoading: false,
      deleteVisible: false,
      closeVisible: false,
      setDialog: false,
      clickRow: null,
      postTitle: null,
      searchObj: {
        examName: null,
        paperName: null,
        external: null,
        status: null
      },
      total: 0,
      size: 10,
      page: 0,
      selectRows: []
    }
  },
  filters: {
    getStatus(val) {
      switch (val) {
        case 0:
          return '未知'
        case 1:
          return '未开始'
        case 2:
          return '进行中'
        case 3:
          return '已结束'
        case 4:
          return '未发布'
        case 9:
          return '已删除'
      }
    }
  },
  created() {
    let params = this.$handleParams('newExamList')
    if (!params) {
      this.searchObj.external = null
    } else {
      if (params.params) {
        this.searchObj.external = 1
      } else {
        this.searchObj.external = null
      }
      if (params.status) {
        this.searchObj.status = params.status
      } else {
        this.searchObj.status = null
      }
    }
    this.loading = true
    this.getList(this.searchObj, this.page, this.size)
  },
  beforeDestroy() {
    sessionStorage.removeItem('newExamList')
    if (this.waitLoading) {
      this.waitLoading.close()
    }
  },
  methods: {
    reset() {
      Object.keys(this.searchObj).forEach(s => {
        this.searchObj[s] = null
      })
      this.getList(this.searchObj, this.page, this.size)
    },
    validDel(row) {
      if (row.status === 4 || row.status === 3) return false
      else return true
    },
    checkLength() {
      // 批量删除 校验
      if (this.selectedExamId.length) {
        this.deleteVisible = true
      } else {
        this.$message.warning('请选择要删除的试卷')
      }
    },
    createNewPaper() {
      // 新建考试
      this.waitLoading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$router.push({ name: 'newPaper' })
    },
    toSetExam(msg) {
      // 跳转指定页面设置考试
      this.setPaperQuestions(
        {
          examId: this.clickRow.examId,
          paperId: this.clickRow.paperId || null
        },
        msg === '设置考题' ? 2 : 3
      )
    },
    setPaperQuestions(row, index) {
      this.$router.push({
        name: 'newPaper',
        params: {
          isSetPaperInfo: true,
          paperId: row.paperId,
          examId: row.examId,
          stepIndex: index
        }
      })
    },
    enterSearch() {
      this.page = 0
      this.getList(this.searchObj, this.page, this.size)
    },
    getList(data, page, size) {
      this.loading = true
      newExamManageApi
        .allExamList(page, size, data)
        .then(res => {
          // 需要增加selected  批量删除需用
          this.list = res.res.data.map(d => {
            d['selected'] = false
            return d
          })
          this.total = res.res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    changePage(page) {
      this.page = page - 1
      this.getList(this.searchObj, this.page, this.size)
    },
    changeSize(size) {
      this.page = 0
      this.size = size
      this.getList(this.searchObj, this.page, this.size)
    },
    handleChange() {
      // true 为选中
      this.selectRows = []
      this.selectedExamId = []
      this.list.forEach(l => {
        if (l.selected) {
          this.selectRows.push(l)
          this.selectedExamId.push(l.examId)
        }
      })
    },
    setReleasePaper(row, s) {
      // 已发布考试跳转设置
      this.$router.push({
        name: 'settingExam',
        params: {
          paperId: row.paperId,
          examId: row.examId,
          type: row.status,
          stepIndex: s === '设置' ? '0' : s === '考题' ? '1' : '2'
        }
      })
    },
    examSet(row, s) {
      this.loading = true
      let editors = ['设置', '考题', '考生']
      if (editors.includes(s)) {
        row.status === 4 ? this.setPaperQuestions(row, s === '设置' ? 1 : s === '考题' ? 2 : 3) : this.setReleasePaper(row, s)
      }
      if (s === '发布') {
        this.clickRow = row
        this.releaseExam(row)
      }
      if (s === '报告') {
        this.examReport(row)
      }
      if (s === '删除') {
        this.handleDeleteExam(row)
      }
      if (s === '关闭') {
        this.clickRow = row
        this.closeExam()
      }
    },
    closeExam() {
      // 关闭考试
      this.closeVisible = true
      this.loading = false
    },
    submitCloseExam(row) {
      this.deleteLoading = true
      let payload = {
        endTime: new Date(),
        examId: row.examId
      }
      newExamManageApi
        .closeExam(payload)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('考试已被关闭')
            this.getList(this.searchObj, this.page, this.size)
          }
        })
        .finally(() => {
          this.closeVisible = false
          this.deleteLoading = false
        })
    },
    handleDeleteExam(row) {
      this.selectedExamId = [row.examId]
      this.deleteVisible = true
      this.loading = false
    },
    deleteExam() {
      this.deleteLoading = true
      newExamManageApi
        .delExam(this.selectedExamId)
        .then(() => {
          this.$message.success('删除成功')
          this.selectedExamId = []
          this.getList(this.searchObj, this.page, this.size)
        })
        .finally(() => {
          this.deleteLoading = false
          this.deleteVisible = false
          this.loading = false
        })
    },
    examReport(row) {
      this.$router.push({
        name: 'examNewResult',
        params: {
          id: row.examId,
          examName: row.name,
          paperBankId: row.paperBankId,
          paperId: row.paperId,
          exam: true,
          name: row.name,
          IsExamLibrary: true,
          type: row.external
        }
      })
      this.loading = false
    },
    releaseExam(row) {
      newExamManageApi
        .postExam(row.examId)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('考试发布成功！')
            this.getList(this.searchObj, this.page, this.size)
          }
          if (res.code === 7223 || res.code === 9019) {
            // 考试没有试卷 或 无参考人员
            this.postTitle = res.code === 7223 ? '设置考题' : '设置参考人员'
            this.setDialog = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getBtn(type) {
      // 控制操作显示
      if (type === 1 || type === 2) {
        let arr = ['设置', '考题', '考生', type === 1 ? '删除' : '报告']
        if (type === 2) arr.push('关闭')
        return arr
      }
      if (type === 3) {
        return ['报告', '删除']
      }
      if (type === 4) {
        return ['发布', '设置', '考题', '考生', '删除']
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin';
@import '../../../../theme/variables';
.exam-main {
  //min-width: 1060px;
  height: 100%;
  background: #f3f4f7;
  .loading__div {
    height: 100%;
  }
  .bread-style {
    padding: 10px 0;
    background: #fff;
  }
  .exam-content {
    height: 100%;
    overflow-y: auto;
    background: #fff;
    border-radius: 6px;
    padding: 10px 20px;
    .search-btn__style {
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 10px;
      svg {
        .wh(13px, 13px);
      }
      div:last-child {
        button {
          width: 80px;
        }
      }
    }
    .exam-data {
      margin-bottom: 10px;
      .font(12px, Regular, #333);
      .info__style {
        width: 65%;
        margin-left: 15px;
        .div_tip {
          min-width: 135px;
          text-align: left;
          margin-top: 10px;
          span:last-child {
            margin-top: 10px;
          }
        }
      }
      .div__s {
        .info-name {
          .font(16px, bold, #000);
        }
        .color__style {
          margin-left: 10px;
          padding: 5px 10px;
          border-radius: 13px;
        }
        .type-span__style {
          margin-left: 12px;
          .font(12px, Regular, #fff);
          border-radius: 13px;
          background: #448bff;
          padding: 5px 15px;
        }
      }
      .btn__style {
        flex: auto;
        padding-right: 10px;
        align-items: flex-end;
        justify-content: flex-end;
        button {
          color: #448bff;
          .wh(20px, 20px);
          &:not(:last-child) {
            margin-right: 12px;
          }
        }
      }
    }
    .card {
      padding: 15px 20px;
      background: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }
    .no-hint {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .page__style {
    padding: 0 20px;
    margin-bottom: 20px;
  }
}
::v-deep {
  .set_suffix .el-input__suffix {
    top: 5px;
    right: 10px;
  }
  .el-input__inner,
  .el-input__inner:focus {
    font-size: 14px;
    border: 1px solid#c9c9c9;
  }
  .el-input__inner {
    &::placeholder {
      .font(14px, Regular, #999);
    }
  }
  .el-tabs__nav-wrap::after {
    height: 0 !important;
  }
  .el-tabs__active-bar,
  .el-tabs__item.is-active,
  .el-tabs__item:hover {
    color: #448bff;
  }
}
@media screen and (max-width: 1400px) {
  .search-btn__style {
    min-height: 80px;
  }
}
</style>
